import { useEffect } from "react";
import { useRouter } from "next/router";
import TagManager from "react-gtm-module";
import Iframe from "react-iframe";

import "tailwindcss/tailwind.css";
// import "../public/scss/fonts.scss";
// import "../public/scss/utilities.scss";

// Components that need to be included dynamically
// import "../public/scss/components/general/definition.scss";
// import "../public/scss/components/page/sliderGrid.scss";
// import "../public/scss/components/page/sliderCards.scss";
// import "../public/scss/components/page/newCtaCardBackgroundImage.scss";
// import "../public/scss/components/blog/postsSlider.scss";

// import "../public/scss/components/general/header.scss";
// import "../public/scss/components/general/footer.scss";
// import "../public/scss/components/page/legalCopy.scss";
// import "../public/scss/components/page/contentsGallery.scss";
// import "../public/scss/components/page/homeTop.scss";

const url = "https://can231.dayforcehcm.com/CandidatePortal/en-US/eastpenn";

export default function MyApp() {
	const router = useRouter();

	useEffect(() => {
		import("react-facebook-pixel")
			.then((x) => x.default)
			.then((ReactPixel) => {
				ReactPixel.init("869792040364816"); // facebookPixelId
				ReactPixel.pageView();

				router.events.on("routeChangeComplete", () => {
					ReactPixel.pageView();
				});
			});
	}, [router.events]);

	useEffect(() => {
		TagManager.initialize({ gtmId: "GTM-TDFM7W7" });
	}, []);

	const iframeContainerStyle = {
		backgroundColor: "rgb(34,34,34)",
		width: "100%",
		height: "100%",
		overflow: "hidden",
	};

	return (
		<div style={iframeContainerStyle}>
			<Iframe
				url={url}
				width="100%"
				height="900"
				className=""
				display="block"
				position="relative"
				frameBorder={0}
			/>
		</div>
	);
}
